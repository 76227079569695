import React from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';

function GameControl({ startGame, gameResult, gameStatus, gameStarted }) {

    return (
        <Row className="justify-content-center" style={{ paddingBottom: '4%' }} >
            {!gameStarted ? (
                <Col md="auto" style={{ width: "600px" }}>
                    <Row className="justify-content-center align-items-center">
                        <button className="button-1" onClick={() => startGame(true)} style={{ margin: 0 }}>
                            {gameResult !== undefined ? (gameStatus.gameTime > 0 ? 'Resume' : 'Start') : (
                                <>
                                    Game engine is loading..
                                    <Spinner animation="border" variant="dark" style={{ borderWidth: '4px', height: '1rem', width: '1rem' }} />
                                </>
                            )}
                        </button>
                    </Row>
                </Col>
            ) : (
                <button className="button-1" onClick={() => startGame(false)} style={{ margin: 0 }}>
                    Stop
                </button>
            )}
        </Row>
    );
}

export default GameControl;
