import React, { useState } from 'react';
import './Accordion.css';

const Accordion = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isAnimating, setIsAnimating] = useState(false);
    const [isAnimatingClose, setIsAnimatingClose] = useState(false);

    const toggleAccordion = () => {
        if (!isOpen) {
          setIsAnimating(true);
          setTimeout(() => {
            setIsOpen(true);
            setIsAnimating(false);
          }, 600); 
        } else {
            setIsAnimatingClose(true);
            setTimeout(() => {
              setIsOpen(false);
              setIsAnimatingClose(false);
            }, 390); 
        }
        props.onToggleClick();
      };

    return (
            <div className="accordion-container" style={{position: "relative"}}>
            {!isOpen && (
                <button 
                    className={`accordion-toggle ${isAnimating ? 'slide-out' : ''}`} 
                    onClick={toggleAccordion}
                >
                    {!isAnimating &&(
                        <div className="sub-title-mobile">
                             {props.toggleText ? props.toggleText : 'Compare'}
                        </div>
                    )}
                    <div>
                        <svg id="more-arrows" className={isAnimating ? ' animate' : ''} >
                            <polygon className="arrow-top" points="37.6,27.9 1.8,1.3 3.3,0 37.6,25.3 71.9,0 73.7,1.3 " transform="scale(0.4)"/>
                            <polygon className="arrow-middle" points="37.6,45.8 0.8,18.7 4.4,16.4 37.6,41.2 71.2,16.4 74.5,18.7 " transform="scale(0.4)"/>
                            <polygon className="arrow-bottom" points="37.6,64 0,36.1 5.1,32.8 37.6,56.8 70.4,32.8 75.5,36.1 " transform="scale(0.4)"/>
                        </svg>
                    </div>
                </button>
            )}
            {isOpen && (
                <button 
                    className={`accordion-toggle ${isAnimatingClose ? 'slide-up open' : 'open'}`} 
                    onClick={toggleAccordion}
                    style={{zIndex: 2}}
                    >
                    {isAnimatingClose &&(
                        <div className='fadeIn'></div>
                    )}
                    <div>
                        <svg id="more-arrows-bottom" className={isAnimatingClose ? ' animate' : ''}>
                            <polygon className="arrow-top" points="37.6,27.9 1.8,1.3 3.3,0 37.6,25.3 71.9,0 73.7,1.3 " transform="scale(0.4)"/>
                            <polygon className="arrow-middle" points="37.6,45.8 0.8,18.7 4.4,16.4 37.6,41.2 71.2,16.4 74.5,18.7 " transform="scale(0.4)"/>
                            <polygon className="arrow-bottom" points="37.6,64 0,36.1 5.1,32.8 37.6,56.8 70.4,32.8 75.5,36.1 " transform="scale(0.4)"/>
                        </svg>
                    </div>
                </button>
            )}
            {(isAnimating || isOpen) && (
                <div className={`accordion-content ${isAnimating ? 'slide-down' : ''} ${isAnimatingClose ? 'slide-up' : ''}`} style={{zIndex: 0}}>
                    {props.children}
                </div>
            )}
            </div>
    );
};

export default Accordion;
