import React from 'react';
import './TvComponent.css';

function TvComponent(props) {
  return (
    <div className="container-tv">
      <div className="wall flexBox-tv">
        <div className="tv flexBox-tv">
          <div className="screen flexBox-tv">
            <div className="Profile">
              <div className="screen-content">{props.children}</div>
            </div>
            {/* Decoration elements */}
            <span style={{ "--i": 23, "--color": "#d990f9" }}></span>
            <span style={{ "--i": 13, "--color": "#1061e7" }}></span>
          </div>
        </div>
        <div className="tvStand">
          <div className="StandSides"></div>
        </div>
      </div>
    </div>
  );
}

export default TvComponent;
