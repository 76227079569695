import React, { useState, useEffect, useRef } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import BaseLeague from '../leagueOptions/BaseLeague';
import OptimisticLeague from '../leagueOptions/OptimisticLeague';
import ArbitrumLeague from '../leagueOptions/ArbitrumLeague';
import './LeagueSelection.css';

function LeagueSelection({ createTeam, onLeagueSelect, isMobile }) {
  const [selectedLeague, setSelectedLeague] = useState(null);
  const containerRef = useRef(null);

  const handleLeagueSelect = (league) => {
    if (selectedLeague === league) {
      setSelectedLeague(null);
      onLeagueSelect(null);
    } else {
      setSelectedLeague(league);
      onLeagueSelect(league);
    }
  };

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setSelectedLeague(null);
      onLeagueSelect(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Container ref={containerRef}>
      <Row className="justify-content-center" style={!isMobile ? { paddingBottom: "0%" } : { paddingBottom: "10%" }}>
        <Col md="auto">
          <div className="sub-title" style={!isMobile ? { fontSize: "26pt" } : {  fontSize: "20pt" }}>
            Select your league
          </div>
        </Col>
      </Row>
      <Row className="justify-content-center" style={{ paddingTop: "5%" }}>
        <Col 
          md={4} 
          onClick={() => handleLeagueSelect('base')} 
          style={{ cursor: 'pointer' }} // Change cursor to pointer
        >
          <BaseLeague 
            createTeam={createTeam}
            onLeagueSelect={() => handleLeagueSelect('base')}
            isSelected={selectedLeague === 'base'}
            data={{ averageTeamCost: 15, numberOfTeams: 11, totalValue: 1000 }}
            isMobile={isMobile}
          />
        </Col>
        <Col 
          md={4} 
          onClick={() => handleLeagueSelect('optimistic')} 
          style={{ cursor: 'pointer' }} // Change cursor to pointer
        >
          <OptimisticLeague 
            onLeagueSelect={() => handleLeagueSelect('optimistic')}
            isSelected={selectedLeague === 'optimistic'}
            isMobile={isMobile}
          />
        </Col>
        <Col 
          md={4} 
          onClick={() => handleLeagueSelect('arbitrum')} 
          style={{ cursor: 'pointer', paddingTop: isMobile ? '5%' : '0%' }} // Change cursor to pointer
        >
          <ArbitrumLeague 
            onLeagueSelect={() => handleLeagueSelect('arbitrum')}
            isSelected={selectedLeague === 'arbitrum'}
            isMobile={isMobile}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default LeagueSelection;
