import React from 'react';
import SlideIn from '../../components/animations/slide/SlideIn.js';
import GameEngine from '../../components/gameEngine/GameEngine.js';

const GameViewContainer = ({
  startAnimation,
  selectedGame,
  gameResult,
  gameCommentary,
  homeFormation,
  awayFormation,
  homeFormationArray,
  awayFormationArray,
  teamsData,
  getLeagueData,
  isMobile,
  gameStarted,
  startGame,
  gameStatus,
  setGameStarted
}) => {
  return (
    <SlideIn startAnimation={startAnimation}>
      {startAnimation && (
        <GameEngine
          selectedGame={selectedGame}
          gameResult={gameResult}
          gameCommentary={gameCommentary}
          homeFormation={homeFormation}
          awayFormation={awayFormation}
          homeFormationArray={homeFormationArray}
          awayFormationArray={awayFormationArray}
          teamsData={teamsData}
          getLeagueData={getLeagueData}
          isMobile={isMobile}
          gameStarted={gameStarted}
          startGame={startGame}
          gameStatus={gameStatus}
          setGameStarted={setGameStarted}
        />
      )}
    </SlideIn>
  );
};

export default GameViewContainer; 