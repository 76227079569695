import React, { Component } from 'react';
import { Container,Col,Spinner, Row,Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SignInButton from '../components/SignInButton.js';
import PlayGameModal from '../components/modal/PlayGameModal.js';
import SlideIn from '../components/animations/slide/SlideIn.js';
import GamesGrid from '../components/gamesGrid/GamesGrid.js';
import NextDivision from '../components/nextDivision/NextDivision.js';
import GameEngine from '../components/gameEngine/GameEngine.js';
import AvailableGamesContainer from '../components/availableGamesContainer/AvailableGamesContainer.js';
import GameViewContainer from '../components/gameViewContainer/GameViewContainer.js';
// helpers
import * as api from '../helpers/api.js';
import * as players from '../helpers/players.js';

import backgroundImage from "../static/stadium-pitch-ng-2.png";

class Schedule extends Component {
  constructor(props) {
    super(props)
    this.state = {
      account: 0,
      formationTypes: [[4,4,2],[4,3,3],[4,5,1],[5,4,1],[3,5,2]],
      homeFormationArray: [4,4,2], //default
      awayFormationArray: [4,4,2], // default
      availableGamesLoading: false,
    }
    this.handlePlayGameClick = this.handlePlayGameClick.bind(this)
  }
  
  async componentDidMount(){
    await this.props.scheduleCallback();
    if (this.props.ownedTeamId > 0){
      await this.props.getTeamNextGameId()
      await this.props.getFormationForGame()
    }
  }

  setStartAnimation(status){
    this.setState({
      startAnimation: status
    })
  } 

  setIsStadiumPlaying(status){
    this.setState({
      isStadiumPlaying: status
    })
  }

  setGameResult(result){
    this.setState({
      gameResult: result
    })
  }

  async componentDidUpdate(prevProps) {
    // console.log(this.props.nextGameInfo)
    // console.log(prevProps, this.props)
    if (this.props.web3 !== undefined && prevProps !== this.props) {
      if (this.props.ownedTeamLeague == undefined){
        // console.log("no league")
      } else {
        if (!this.state.availableGamesLoading){
          // console.log(this.props.ownedTeamLeague)
          let teamLeague = this.props.ownedTeamLeague;
          this.setAvailableGamesSlides(teamLeague)
          this.setState({availableGamesLoading: true})
        }
        let leagueFullData = await this.props.getLeagueFullData();
        this.setState({
          leagueFullData: leagueFullData
        })
      }
    }
  }

  async updateGamesData(leagueFullData){
    // console.log(leagueFullData)
    let promises = leagueFullData.schedule.map(game => api.addGame(game[3], 8453));
    await Promise.all(promises);
    promises = Array.from({ length: 55 }, (_, i) => 
      api.updateGameScore(i + 1, 8453));
    await Promise.all(promises);
  }

  async setAvailableGamesSlides(teamLeague) {
    var availableGames = []
    if (this.props.availableGames === undefined){
      availableGames = await this.props.getAvailableGames(teamLeague);
    } else {
      availableGames = this.props.availableGames
    }
    // console.log(availableGames)
    let availableGamesSlides = availableGames.map(game => {
      let imageUrlHome = players.uriToImage(game.teamUriHome)
      let imageUrlAway = players.uriToImage(game.teamUriAway)
      return {
        imageUrlHome: `${imageUrlHome}`, 
        imageUrlAway: `${imageUrlAway}`,
        teamNameHome: `${game.teamNameHome}`,
        teamNameAway: `${game.teamNameAway}`, 
        buttonText: "Play",
        gameStart: `${game.game_start_time}`,
        gameId: `${game.game_id}`
      };
    });
    this.setState({
      availableGames: availableGames,
      availableGamesSlides: availableGamesSlides
    })
    // console.log(availableGamesSlides)
    return availableGames;
  }

  stringToArrayOfNumbers(str) {
    return str.split('-').map(Number);
  }

  setSelectedItem(item){
    let formationArray = this.stringToArrayOfNumbers(item)
    this.setState({
      selectedFormation: item,
      formationArray: formationArray
    })
  }

  getOpponent(){
    if (this.props.nextGameInfo[0] === this.props.ownedTeamId){
      return this.props.nextGameInfo[1]
    } else {
      return this.props.nextGameInfo[0] 
    }
  }

  generateDate(time_){
    // roundup to :30
    return new Date((Number(time_) +1700) * 1000).toLocaleDateString('en-GB', { hour: '2-digit', minute: '2-digit' });
  };

  handleShowSetFormation(){
    this.setState({
      showSetFormation: true 
    })
  }

  async handleShowPastGames(){
    this.setState({
      pastGamesLoaded: false,
    })
    if (!this.state.pastGamesClicked){
      await this.updateGamesData(this.state.leagueFullData);
      this.setState({
        pastGamesClicked: true,
        pastGamesLoaded: true,
        teamSchedule: this.state.pastGames.concat(this.state.teamSchedule),
      })
    }
  }

  async handleShowFullSchedule(){
    if (this.state.showFullSchedule){
      this.setState({
        showFullSchedule: false
      })
      return
    }
    this.setState({
      scheduleLoading: true,
    })

    let calendar = await api.getLeagueCalendar(this.props.ownedTeamLeague, 8453);
    // console.log(calendar)

    let teamSchedule = []
    let pastGames = []
    // console.log(leagueFullData)
    calendar.map((game) => {
        if(game.home_team_id == this.props.ownedTeamId || game.away_team_id == this.props.ownedTeamId){
          if (game.game_start_time > Math.floor(Date.now() / 1000)){
            // console.log(game)
            teamSchedule.push(game)
          } else {
            pastGames.push(game)
          }
        }
      }
    )
    // // console.log(teamSchedule)
    // // console.log(pastGames)
    
    const sortedSchedule = teamSchedule.slice().sort(this.sortFunction);
    const pastGamesSorted = pastGames.slice().sort(this.sortFunction);
    teamSchedule = Promise.all(sortedSchedule.map(async (game, i) => {
      return(
        <>
        <tr key={i} >
          <td>{game.home_team_name}</td>
          <td>{game.away_team_name}</td>
          <td>{this.generateDate(game.game_start_time)}</td>
          <td>{game.game_id}</td>
        </tr>
        </>
      )
    })).then(result => {
      this.setState({
        scheduleLoading: false,
        teamSchedule: result,
      })
    });
    pastGames = Promise.all(pastGamesSorted.map(async (game, i) => {
      // console.log(game[3],gameResult)
      return(
        <>
        <tr key={i} >
          <td>{game.home_team_name}</td>
          <td>{game.away_team_name}</td>
          <td>{this.generateDate(game.game_start_time)}</td>
          <td>{game.game_id}</td>
          <td>{game.result}</td>
        </tr>
        </>
      )
    })).then(result => {
      this.setState({
        pastGamesLoaded: true,
        pastGames: result
      })
    });

    // console.log(teamSchedule)
    this.setState({
      scheduleLoaded: true,
      showFullSchedule: true
    })
  
  }

  sortFunction(a, b) {
    return a[2] - b[2];
  }

  async handlePlayGameClick(gameId,index){
    // console.log(gameId,this.state.availableGames)
    let gameInfo = this.state.availableGames.find(game => game.game_id == gameId);
    // console.log(gameInfo)
    this.setState({selectedGame: gameInfo})
    this.setIsStadiumPlaying(true)
    this.setStartAnimation(!this.state.startAnimation)
    // this.setState({ showPlayGameModal: true })
    let homeFormation = await this.props.loadFormationById(gameInfo.home_team_id)// (this.props.nextGameInfo[0]
    let awayFormation = await this.props.loadFormationById(gameInfo.away_team_id) //(this.props.nextGameInfo[1]
    // console.log(homeFormation, awayFormation)
    let homeFormationArray = this.state.formationTypes[Number(homeFormation[0])]
    let awayFormationArray = this.state.formationTypes[Number(awayFormation[0])]
    this.setState({
      homeFormation: homeFormation,
      awayFormation: awayFormation,
      homeFormationArray: homeFormationArray,
      awayFormationArray: awayFormationArray,
    })
    var result = {}
    try{
      result = await this.props.handlePlayGame(gameId)
      // result = {0: "7", 1: "3", 2: "1701162143", 3:"3", 4: "2", teamNameHome: "Xatt Lahmar FC", teamNameAway: "ChadBallers"}
      let teams = {
        teamNameHome: this.state.selectedGame.home_team_name, 
        teamNameAway: this.state.selectedGame.away_team_name
      };
      result = {
        0: result[0],
        1: result[1],
        2: result[2],
        3: result[3],
        4: result[4],
        teamNameHome: teams.teamNameHome,
        teamNameAway: teams.teamNameAway
      };
      this.setGameResult(result)
      // this.updateGamesData(this.state.leagueFullData);
      // this.setAvailableGamesSlides()
      this.setIsStadiumPlaying(false)
      this.setState({ generalModalStatusGame: "success" })
    }
    catch(error){
      this.setIsStadiumPlaying(false)
      this.setState({ showPlayGameModal: false })
      this.setState({ generalModalStatusGame: "" })
    }
    let leagueData = await this.props.getLeagueData(this.props.ownedTeamLeague);
    // var gameCommentary = await api.getGameCommentary(result, leagueData)
    // console.log(gameCommentary)
    // await api.speakGameCommentary(result, gameCommentary.join('\n'))
    // this.setState({
    //   gameCommentary: gameCommentary
    // })
  }

  async getTeamName(inputValue) {
    // console.log(inputValue)
    if (this.props.teamsData !== undefined){
      for(let i = 0; i < this.props.teamsData.length; i++){
        let game = this.props.teamsData[i];
        if (game[1] == inputValue) {
          // console.log(game[2])
          if (game[2].length > 0){
            return game[2]; 
          } else {
            let name = await this.props.getTeamName(inputValue);
            // console.log(name)
            return name;
          }
        } 
      }
  
      let name = await this.props.getTeamName(inputValue);
      // console.log(name)
      return name;
    } else {
      let name = await this.props.getTeamName(inputValue);
      // console.log(name)
      return name;
    }
  }

  render(){
    return (
      <>
      <div style={ !this.props.isMobile ? {} : {maxWidth: "500px"}}>
        <div style={!this.props.isMobile ? (this.props.ownedTeamId !== undefined && this.props.ownedTeamId > 0 ? {
            backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${backgroundImage})`, 
            backgroundBlendMode: 'soft-light',
            backgroundSize: 'cover', 
            backgroundRepeat: 'no-repeat',
            minHeight: '150vh',
            minWidth: '100%'
          } : {}) : (
            this.props.ownedTeamId !== undefined && this.props.ownedTeamId > 0 ? {
              backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), url(${backgroundImage})`, 
              backgroundBlendMode: 'soft-light',
              backgroundSize: 'cover', 
              backgroundRepeat: 'no-repeat',
              minHeight: '150vh',
              minWidth: '100%'
            } : {})
        }>
          <GameViewContainer
            startAnimation={this.state.startAnimation}
            selectedGame={this.state.selectedGame}
            gameResult={this.state.gameResult}
            gameCommentary={this.props.gameCommentary}
            homeFormation={this.props.homeFormation}
            awayFormation={this.props.awayFormation}
            homeFormationArray={this.state.homeFormationArray}
            awayFormationArray={this.state.awayFormationArray}
            teamsData={this.props.teamsData}
            getLeagueData={this.props.getLeagueData}
            isMobile={this.props.isMobile}
            gameStarted={this.state.gameStarted}
            startGame={this.startGame}
            gameStatus={this.state.gameStatus}
            setGameStarted={this.setGameStarted}
          />
        {this.props.ownedTeamId > 0 && this.state.selectedGame == undefined && (
          <div style={{ display: 'flex', justifyContent: 'center', padding: '2% 2% 0 2%' }}>
            <AvailableGamesContainer 
              startAnimation={this.state.startAnimation}
              slides={this.state.availableGamesSlides}
              buttonFunc={this.handlePlayGameClick}
              getNextDivisionTeams={this.props.getNextDivisionTeams}
              leagueId={this.props.ownedTeamLeague}
              ownedTeamId={this.props.ownedTeamId}
              isMobile={this.props.isMobile}
            />
          </div>
        )}
        {(this.props.nextGameId !== undefined && this.props.nextGameId > 0 && this.props.nextGameInfo !== undefined) ? (
            <>
            <Row style={{display: 'flex', justifyContent: 'center', paddingRight:"2%", paddingTop: '1%'}}>
              <span className='table-title'>
                Next Game
              </span>
            </Row>
            <Row style={{paddingTop:'1%', display: 'flex', justifyContent: 'center',paddingRight:"3%" }}>
              <span className="button-bold">
              {this.state.homeTeamName} 
              </span>
              <span style={{paddingTop:'0.5%', display: 'flex', justifyContent: 'center'}}>
                vs
              </span>
              <span className="button-bold">
              {this.state.awayTeamName}
              </span>
            </Row>
            <Row style={{paddingTop:'3%', display: 'flex', justifyContent: 'center', paddingRight:"3%" }}>
              <span className="button-bold">
                Kick Off: {this.generateDate(this.props.nextGameInfo[2])} 
              </span>
            </Row>
            <Row style={{paddingTop:'3%', display: 'flex', justifyContent: 'center', paddingRight:"3%" }}>
              <Col md="auto" xs="auto" >
                <Link to="/formation" style={{ textDecoration: 'none', color: 'inherit' }}>
                  <button className="button-1b" onClick={() => this.handleShowSetFormation()}>
                    Set Game Formation
                  </button>
                </Link>
              </Col>
            </Row>
          </>
          ):
          (
            <>
            {this.props.account !== undefined ? (
              <>
               {!this.props.ownedTeamId > 0 && (
                <Row className="justify-content-center" style={{ paddingTop: '1%'}}>
                  <Col md="auto" xs="auto">
                    <Link to="/app" style={{ textDecoration: 'none', color: 'inherit' }}>
                      <button className="button-1">
                        Create a team to play a game.
                      </button>
                    </Link>
                  </Col>
                </Row>
              )}
              {/* {this.props.availableGames == undefined && this.props.nextGameInfo.length === 0 && 
                <Row className="justify-content-center" style={{ paddingTop: '1%', paddingBottom: "2%"}}>
                  <Col md="auto" xs="auto">
                    <div style={{ color: "black"}}>
                      Your League has not started yet. Check back soon!
                    </div>
                  </Col>
                </Row>
              }  */}
              </>
            ):(
              <>
                <Row className="justify-content-center" style={{ paddingTop: '1%'}}>
                  <Col md="auto" xs="auto">
                    <SignInButton
                      loginButtonFallback={this.props.loginButtonFallback}
                      customText={'Sign in to display your schedule'}
                      accountListenerFallback={this.props.accountListenerFallback}
                      isMobile={this.props.isMobile}
                    />
                  </Col>
                </Row>
              </>
            )
          }
            </>
        )}
        </div>
      <PlayGameModal 
        show={this.state.showPlayGameModal}
        onHide={() => this.setState({ showPlayGameModal: false })}
        handlePlayGame={this.handlePlayGameClick}
        statusMsg={"Game played! Check the result in your schedule below..."}
        status={this.state.generalModalStatusGame}
      />
      </div>
      </>
    );
  }
}

export default Schedule;
