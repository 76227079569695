import React, { useState } from 'react';
import TvComponent from '../tvComponent/TvComponent';
import GameView from '../gameView/GameView';
import GameControl from '../gameView/GameControl';

const GameEngine = ({
  selectedGame,
  gameResult,
  gameCommentary,
  homeFormation,
  awayFormation,
  homeFormationArray,
  awayFormationArray,
  teamsData,
  getLeagueData,
  isMobile,
}) => {
  const [gameStatus, setGameStatus] = useState({score: [0,0], gameTime: 0});
  const [gameStarted, setGameStarted] = useState(false);

  const updateGameStatus = (status) => {
    setGameStatus(status);
  };

  const startGame = (bool) => {
    setGameStarted(bool);
  };

  return (
    <>
      <TvComponent>
        <GameView
          selectedGame={selectedGame}
          gameResult={gameResult}
          gameSummary={gameCommentary}
          homeFormation={homeFormation}
          awayFormation={awayFormation}
          homeFormationArray={homeFormationArray}
          awayFormationArray={awayFormationArray}
          teamsData={teamsData}
          getLeagueData={getLeagueData}
          isMobile={isMobile}
          updateGameStatus={updateGameStatus}
          gameStarted={gameStarted}
        />
      </TvComponent>
      <GameControl
        startGame={startGame}
        gameResult={gameResult}
        gameStatus={gameStatus}
        gameStarted={gameStarted}
      />
    </>
  );
};

export default GameEngine;