import React, { useContext, useState } from 'react';
import { FaUserCircle } from 'react-icons/fa';
import * as IoIcons from 'react-icons/io';
import { Web3AuthContext } from '../../contexts/web3AuthContext';
import UserModal from "../UserModal/UserModal";
// Styles
import './LoginButton.scss';

const LoginButton = ({ isMobile, onLoginClick, loginText = "Sign In" }) => {
  const { userInfo, isLoggedIn, login, logout } = useContext(Web3AuthContext);
  const [showModal, setShowModal] = useState(false);
  const [imageError, setImageError] = useState(false);

  const handleIconClick = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const handleLogin = async () => {
    try {
      const userData = await login(); 
      onLoginClick(userData); 
    } catch (error) {
      console.error("Login failed:", error);
    }
  };

  const handleLogout = async () => {
    await logout();
    onLoginClick(); 
  };


  return (
    <div className="login-button-container">
      {isLoggedIn ? (
        <div style={!isMobile ? { minHeight: '92px', display: 'flex', alignItems: 'center' } : { display: 'flex', alignItems: 'center' }}>
          {userInfo?.profileImage && !imageError ? (
            // <img
            //   src={userInfo.profileImage}
            //   alt="User Profile"
            //   className="profile-image"
            //   onClick={handleIconClick}
            //   onError={() => setImageError(true)} // Show icon if image fails to load
            // />
            <div >
                <button className="button-formation" onClick={handleIconClick}  >
                    <span className='table-title' style={{ color: "black", float: "centre" }}>
                        Manager Id: 
                    </span>
                    <span className='sub-title-mobile' > 
                        {truncate(userInfo.wallet)} 
                    </span>
                </button>
            </div>
          ) : (
            // <FaUserCircle
            //   className="profile-icon"
            //   onClick={handleIconClick}
            //   size={30} 
            // />
            <div >
                <button className="button-formation" onClick={handleIconClick}  >
                    <span className='table-title' style={{ color: "black", float: "centre" }}>
                        Manager Id: 
                    </span>
                    <span className='sub-title-mobile' > 
                        {truncate(userInfo.wallet)} 
                    </span>
                </button>
            </div>
          )}      
          <UserModal
            showModal={showModal}
            handleClose={handleClose}
            userInfo={userInfo}
            logout={handleLogout}
          />
        </div>
      ) : (
        <button onClick={handleLogin} className='gradient-button'>
          <div className='sub-title-mobile'>
            {loginText} 
            {loginText === "Sign In" && <IoIcons.IoIosFlash />}
          </div>
        </button>
      )}
    </div>
  );
};

function truncate(str) {
    return str.substring(2, 8);
}

export default LoginButton;
