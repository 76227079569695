import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import SkillBar from '../skillBar/SkillBar';

const PlayerAttributesTraining = ({
  selectedItem,
  selectedAttribute,
  handleSelectAttribute,
  isMobile,
  canTrain,
  trainingCooldown,
  md = 7,
  xs = 10
}) => {

  return (
    <Col md={md} xs={xs} style={{ display: "flex", justifyContent: "flex-start", paddingLeft: '3%' }}>
      <Row style={{ paddingLeft: "1%", paddingBottom: "3%" }}>
        {canTrain ? (
          <table style={{ fontSize: '1rem', width: "100%", borderRadius: "15%", textAlign: "center" }}>
            <thead>
              <tr>
                <th>{selectedItem.body}</th>
              </tr>
            </thead>
            <tbody>
              {selectedItem.skillsName.map((row, index) => (
                selectedItem.skillsName[index] !== "Learning Rate" && (
                  <tr
                    key={index}
                    style={{
                      backgroundColor: selectedAttribute === index ? '#e0f7fa' : 'transparent'
                    }}
                  >
                    <td>{row}</td>
                    <td style={{ paddingLeft: "2%" }}>
                      <div style={{ width: "100px" }}>
                        <SkillBar
                          skillValue={selectedItem.skillsValue[index]}
                          skillName={selectedItem.skillsName[index]}
                          isMobile={isMobile}
                        />
                      </div>
                    </td>
                    <td style={{ paddingLeft: "10%" }}>
                      <div className="sub-title-mobile">{selectedItem.skillsValue[index]}</div>
                    </td>
                    <td style={{ paddingLeft: "15%" }}>
                      <button
                        className={
                          selectedAttribute === index
                            ? "button-1-nopad-selected"
                            : "button-1-nopad"
                        }
                        style={{
                          height: "50%",
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                        onClick={() => handleSelectAttribute(index)}
                        disabled={!canTrain}
                      >
                        {selectedAttribute === index ? (
                          <span style={{ fontSize: "1rem", color: 'green' }}>✔</span>
                        ) : (
                          <span style={{ fontSize: "1rem", color: 'gray' }}>○</span>
                        )}
                      </button>
                    </td>
                  </tr>
                )
              ))}
            </tbody>
          </table>
        ) : (
          <div style={{ paddingTop: "10px", display: "flex", flexDirection: "column", alignItems: "center" }}>
            <div style={{ textAlign: "center" }}>Player already recently trained. He will be ready in:</div>
            <div className="button-1" style={{ cursor: 'not-allowed', textAlign: "center" }}>
              {trainingCooldown} seconds
            </div>
          </div>
        )}
      </Row>
    </Col>
  );
};

export default PlayerAttributesTraining;
