import React from 'react';
import Navbar from '../navbar/Navbar';
import LoginBanner from '../login/LoginBanner';
import './Layout.scss';

const Layout = ({ loginButtonFallback, signer, loginMethod, startTimer, isMobile, children }) => {
  return (
    <div className="layout-grid">
      <Navbar />
      <LoginBanner
        loginButtonFallback={loginButtonFallback}
        signer={signer}
        loginMethod={loginMethod}
        startTimer={startTimer}
        isMobile={isMobile}
      />
      <div className="content-area">
        {children}
      </div>
    </div>
  );
};

export default Layout;
