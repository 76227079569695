import React from 'react';
import { Row, Col } from 'react-bootstrap';
import LeagueTable from '../LeagueTable'; // Adjust the import path as necessary

const LeagueInfo = ({ leaguePrize, teamsData, getLeagueData, isMobile }) => (
  <div style={{ border: '1px solid #ccc', borderRadius: '16px', padding: '16px', margin: '16px 0', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
    <Row style={{ paddingTop: '2%', display: 'flex', justifyContent: 'center', paddingRight: "2%" }}>
      <div className='sub-title-mobile'>
        Team&nbsp; League
      </div>
    </Row>
    <Row style={{ paddingTop: '1%', display: 'flex', justifyContent: 'center', paddingRight: "2%" }}>
      <Col md="auto">
        <span className="col-centered comic-neue">
          League Prize <span className='sub-title-mobile'> {leaguePrize !== undefined ? (leaguePrize + ' $') : "N/A"} </span>
        </span>
      </Col>
    </Row>
    <Row className="justify-content-center" style={{ paddingTop: '3%' }}>
      <span className='table-title'>
        League Ranking
      </span>
    </Row>
    <Row className="justify-content-center" style={{ paddingTop: '1%', overflowX: 'auto'}}>
        <LeagueTable
          teamsData={teamsData}
          getLeagueData={getLeagueData}
          isMobile={isMobile}
        />
    </Row>
  </div>
);

export default LeagueInfo;