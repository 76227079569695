import React from 'react';
import { Row, Container } from 'react-bootstrap';
import GamesGrid from '../gamesGrid/GamesGrid.js';

const AvailableGamesContainer = ({ startAnimation, slides, buttonFunc, getNextDivisionTeams, leagueId, ownedTeamId, isMobile }) => (
  <div style={{ 
    width: '1000px', 
    borderRadius: '16px', 
    padding: '16px', 
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', 
    backgroundColor: '#ffffff',
    border: '1px solid #ccc',
    }}>
    <Row style={{ display: 'flex', justifyContent: 'center', paddingTop: '1%' }}>
      <span className='sub-title-mobile'>
        {!startAnimation ? 'Available Games' : 'Match View'}
      </span>
    </Row>
    <Container
      style={{
        maxWidth: '1000px',
        width: '80%',
        minHeight: isMobile ? '185px' : '140px',
        borderRadius: '15px',
        padding: '10px',
        position: 'relative',
        zIndex: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <GamesGrid
        slides={slides}
        buttonFunc={buttonFunc}
        getNextDivisionTeams={getNextDivisionTeams}
        leagueId={leagueId}
        ownedTeamId={ownedTeamId}
        isMobile={isMobile}
      />
    </Container>
  </div>
);

export default AvailableGamesContainer;
